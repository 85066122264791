import React from "react";
import "../../styles/service.css";
import Features from "./Features/FeaturesDataAccessblity";
import FeaturesDataAvailablity from "./Features/FeaturesDataAvailablity";
import FeaturesDataStrategy from "./Features/FeaturesDataStrategy";
import FeaturesOtherAIproduct from "./Features/FeaturesOtherAIproduct";
const Service = ({ getClick, handleClick }) => {
  return (
    <>
      <section
        className={getClick === 0 ? "section-show" : "section-hide"}
        id="service"
      >
        {" "}
        {/*Section 0*/}
        <div className="container">
          {/* <div className="service_top-content">
          <h6 className="subtitle">Our Services</h6>
          <h2>Save time managing your business</h2>
          <h2 className="highlight">our best services</h2>
        </div> */}
          <div className="anna">
            <h2>
              MEET <span className="highlight">AANA</span> , CHAT BASED AI AGENT
              WHO IS YOUR OWN AI DATA ANALYST
            </h2>
            <p className="description">
              Aana is an AI-powered chat based assistant that can answer your
              questions instantly based on range of data information from
              internal transaction data to external competition data. No more
              need to wait for hours to analyze your data and get answers. Ask &
              Analyze in minutes now, not hours!
            </p>
            <iframe
              width="100%"
              height="635"
              src="https://www.youtube.com/embed/PGcJUvVbX5o?si=FVcCd56KmOjifuDc"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <Features />
      </section>

      <section className={getClick === 1 ? "section-show" : "section-hide"}>
        {" "}
        {/*Section 1*/}
        <div className="container">
          {/* <div className="service_top-content">
          <h6 className="subtitle">Our Services</h6>
          <h2>Save time managing your business</h2>
          <h2 className="highlight">our best services</h2>
        </div> */}
          <div className="anna">
            <h2>
              MEET <span className="highlight">AANA</span> , CHAT BASED AI AGENT
              WHO IS YOUR OWN AI DATA ANALYST
            </h2>
            <p className="description">
              Aana is an AI-powered chat based assistant that can answer your
              questions instantly based on range of data information from
              internal transaction data to external competition data. No more
              need to wait for hours to analyze your data and get answers. Ask &
              Analyze in minutes now, not hours!
            </p>
            <iframe
              width="100%"
              height="635"
              src="https://www.youtube.com/embed/PGcJUvVbX5o?si=FVcCd56KmOjifuDc"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <Features />
      </section>

      <section className={getClick === 2 ? "section-show" : "section-hide"}>
        {" "}
        {/*Section 2*/}
        <div className="container">
          {/* <div className="service_top-content">
          <h6 className="subtitle">Our Services</h6>
          <h2>Save time managing your business</h2>
          <h2 className="highlight">our best services</h2>
        </div> */}
          <div className="anna">
            <h2>
              ADVANCED <span className="highlight">DATA</span> LAKE:
            </h2>
            <p className="description">
              Streamline your data infrastructure with our advanced data lake
              solution. Featuring optimized storage and processing, it ensures
              the availability of various data types. Aana serves as your
              central monitoring hub, offering a comprehensive solution for
              robust and cost-effective data management.
            </p>
            
            {/* <iframe
              width="100%"
              height="635"
              src="https://www.youtube.com/embed/PGcJUvVbX5o?si=FVcCd56KmOjifuDc"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe> */}
          </div>
        </div>

        <FeaturesDataAvailablity/>
      </section>

      <section className={getClick === 3 ? "section-show" : "section-hide"}>
        {" "}
        {/*Section 3*/}
        <div className="container">
          {/* <div className="service_top-content">
          <h6 className="subtitle">Our Services</h6>
          <h2>Save time managing your business</h2>
          <h2 className="highlight">our best services</h2>
        </div> */}
          <div className="anna">
            <h2>
              <span className="highlight">Data & Business</span> Strategy
              Consulting :
            </h2>
            <p className="description">
              Propel your organization forward with our collaborative approach
              to building data and business strategies. Our experts not only
              deliver tailored strategies but actively support execution,
              ensuring tangible results for sustained growth.
            </p>
            
            {/* <iframe
              width="100%"
              height="635"
              src="https://www.youtube.com/embed/PGcJUvVbX5o?si=FVcCd56KmOjifuDc"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe> */}
          </div>
        </div>
        <FeaturesDataStrategy/>
      </section>

      <section className={getClick === 4 ? "section-show" : "section-hide"}>
        {" "}
        {/*Section 4*/}
        <div className="container">
          {/* <div className="service_top-content">
          <h6 className="subtitle">Our Services</h6>
          <h2>Save time managing your business</h2>
          <h2 className="highlight">our best services</h2>
        </div> */}
          <div className="anna">
            <h2>
              <span className="highlight">AI Data Products</span> for
              Accelerated Growth:
            </h2>
            <p className="description">
              Supercharge your company's growth with our AI Data Products. Our
              skilled team of data scientists leverages advanced techniques to
              build tailored solutions that harness the power of your
              organization's data. With a proven track record and a
              client-centric approach, we deliver AI-driven products that propel
              your business forward, unlocking new possibilities and
              opportunities.
            </p>
            
            {/* <iframe
              width="100%"
              height="635"
              src="https://www.youtube.com/embed/PGcJUvVbX5o?si=FVcCd56KmOjifuDc"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe> */}
          </div>
        </div>
        <FeaturesOtherAIproduct/>
      </section>
    </>
  );
};

export default Service;
