import React from "react";
import "../../../styles/features.css";
import tailorSolution from "../../../Assets/tailered_to_everyneed.jpeg";
import streamline from "../../../Assets/Streamline_infra.jpeg";
import dataprivacy from "../../../Assets/Data_lake_privacy.jpeg";
import insight from "../../../Assets/data_lake_monitoring.jpeg";

const FeaturesDataAvailablity = () => {
  return (
    <section id="service">
      <div className="container">
        <div className="features_top-content">
          <h2>HIGHLIGHTED FEATURES</h2>
          <h2 className="highlight">Make Your Work Easier & Faster !</h2>
        </div>
        <div className="features_container">
          {/* Feature 1 */}
          <div className="feature">
            <div className="feature_img">
              <img src={tailorSolution} alt="tailorSolution" />
            </div>
            <div className="feature_content">
              <h2>Tailored Solutions for Every Need:</h2>
              <p className="description">
                No two organizations are the same. That's why our Data Warehouse
                solutions are meticulously crafted to align perfectly with your
                unique needs and objectives. From startups to enterprises, we
                deliver infrastructure that drives results.
              </p>
            </div>
          </div>

          {/* Feature 2 */}
          <div className="feature">
            <div className="feature_content">
              <h2>Streamlined Infrastructure for Unparalleled Performance:</h2>
              <p className="description">
                Go beyond storage with our Data Warehouse solutions. Experience
                streamlined infrastructure that enhances agility, scalability,
                and efficiency. With optimized storage, processing, and
                comprehensive monitoring, your data ecosystem is primed for
                success.
              </p>
            </div>
            <div className="feature_img">
              <img src={streamline} alt="streamline" />
            </div>
          </div>

          {/* Add more features as needed */}
          {/* Feature 3 */}
          <div className="feature">
            <div className="feature_img">
              <img src={dataprivacy} alt="dataprivacy" />
            </div>
            <div className="feature_content">
              <h2>Expertise in Data Privacy and Governance:</h2>
              <p className="description">
                In today's regulatory landscape, data privacy and governance are
                non-negotiable. Our expertise ensures that your data remains
                secure and compliant. From privacy measures to ethical data
                management practices, trust us to safeguard your data integrity.
              </p>
            </div>
          </div>

          {/* Feature 4 */}
          <div className="feature">
            <div className="feature_content">
              <h2>Unlock Insights, Drive Innovation:</h2>
              <p className="description">
                Your data holds the key to innovation. With our solutions,
                unleash its full potential. Make informed decisions, drive
                innovation, and stay ahead of the curve in your industry.
              </p>
            </div>
            <div className="feature_img">
              <img src={insight} alt="insight" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesDataAvailablity;
