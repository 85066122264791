import React from "react";
import "../../styles/getintouch.css";

const GetInTouch = () => {
  return (
    <section className="getintouch">
      <div className="container">
        <div className="getintouch_wrapper">
          <div className="getintouch_content">
            <h6 className="subtitle">Let's work</h6>
            <h2>
              Get In Touch With Us
            </h2>
            <p className="description">
              Have a question or want to learn more about our AI solutions?. We
              love to hear from you. Here's how you can reach us..
            </p>
          </div>
          
          <div className="contact-info">
          <i class="ri-mail-send-line"></i>
            <h3>Talk to Us</h3>
            <p>
              Just send us an email through the
              provided address below:
            </p>
            <a href="mailto:sushil@ainextbit.com" className="email">sushil@ainextbit.com </a>
          </div>
          
        </div>
      </div>
    </section>
  );
};

export default GetInTouch;
