import React from "react";
import "../../../styles/features.css";
import realtime from "../../../Assets/Fraud_detect.jpeg";
import chatgptcustom from "../../../Assets/custom_GPT_model.jpeg";
import carprice from "../../../Assets/car_price.jpeg";
import imgprocessing from "../../../Assets/image_procesing.jpeg";

const FeaturesOtherAIproduct = () => {
  return (
    <section id="service">
      <div className="container">
        <div className="features_top-content">
          <h2>HIGHLIGHTED FEATURES</h2>
          <h2 className="highlight">Make Your Work Easier & Faster !</h2>
        </div>
        <div className="features_container">
          {/* Feature 1 */}
          <div className="feature">
            <div className="feature_img">
              <img src={realtime} alt="realtime" />
            </div>
            <div className="feature_content">
              <h2>Realtime Fraud Detection Engine:</h2>
              <p className="description">
                Safeguard your business in real-time with our state-of-the-art
                Fraud Detection Engine, leveraging advanced AI for instant
                threat identification and adaptive learning. Seamlessly
                integrate into your systems, ensuring proactive protection
                against evolving fraud tactics.
              </p>
            </div>
          </div>

          {/* Feature 2 */}
          <div className="feature">
            <div className="feature_content">
              <h2>Generative AI with Chat GPT Custom Models:</h2>
              <p className="description">
                Seamlessly enhance customer engagement and brand presence with
                dynamic, AI-driven conversations tailored to your unique needs.
                Customize ChatGPT models using client data and productionize it
                at scale.
              </p>
            </div>
            <div className="feature_img">
              <img src={chatgptcustom} alt="chatgptcustom" />
            </div>
          </div>

          {/* Add more features as needed */}
          {/* Feature 3 */}
          <div className="feature">
            <div className="feature_img">
              <img src={carprice} alt="carprice" />
            </div>
            <div className="feature_content">
              <h2>Car Price Engine:</h2>
              <p className="description">
                Revolutionize your car buying experience with our Car Price
                Engine – precision pricing and real-time market insights at your
                fingertips. Find the perfect ride at the best value, making car
                shopping as smooth as the drive itself.
              </p>
            </div>
          </div>

          {/* Feature 4 */}
          <div className="feature">
            <div className="feature_content">
              <h2>
                Image Processing(OCR, Face Detection, Dent & scratch
                detections)
              </h2>
              <p className="description">
                Our Image Processing suite, featuring OCR for seamless text
                extraction, Face Detection for enhanced security, and Dent and
                Scratch Detection for meticulous quality assessment. Transform
                the way you handle images – precision, security, and quality
                assurance in a single, integrated solutions.
              </p>
            </div>
            <div className="feature_img">
              <img src={imgprocessing} alt="imgprocessing" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesOtherAIproduct;
