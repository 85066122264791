import React, { useState, useRef, useEffect} from "react";
import logo from "../../Assets/Logo.png";
import "../Header/Header.css";

const Header = ({ getClick, handleClick, theme, toggleTheme }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const headerRef = useRef(null)

    const headerFunc = ()=> {
      if(document.body.scrollTop > 80 || document.documentElement.scrollTop > 80){
        headerRef.current.classList.add("header_shrink")
      } else{
        headerRef.current.classList.remove("header_shrink")
      }
    }

    useEffect(() =>{
      window.addEventListener('scroll', headerFunc)

      return ()=> window.removeEventListener("scroll", headerFunc);
    }, [])

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (headerRef.current && !headerRef.current.contains(event.target)) {
          setMobileMenuOpen(false);
        }
      };
    
      window.addEventListener("click", handleClickOutside);
    
      return () => {
        window.removeEventListener("click", handleClickOutside);
      };
    }, []);

    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const scrollToBottom = () => {
      const body = document.body;
      const html = document.documentElement;
  
      const height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
  
      window.scrollTo({ top: height, behavior: "smooth" });
    };

  

  return (
    <header className="header" ref={headerRef}>
      <div className="nav_wrapper">
        <div className="logo" >
          <img src={logo} alt="logo" />
        </div>
        <div className={`navigation ${mobileMenuOpen ? "mobile_open" : ""}`}>
          <button
            className={getClick === 0 ? "secondary_btn" : "primary_btn"}
            onClick={() => {
            handleClick(0);
            scrollToTop();
            setMobileMenuOpen(false);
            }}
          >
            Home
          </button>
          <button
            className={getClick === 1 ? "secondary_btn" : "primary_btn"}
            onClick={() => {
              handleClick(1);
              scrollToTop();
              setMobileMenuOpen(false);
            }}
          >
            Data Accessiability
          </button>
          <button
            className={getClick === 2 ? "secondary_btn" : "primary_btn"}
            onClick={() => {
              handleClick(2);
              scrollToTop();
              setMobileMenuOpen(false);
            }}
          >
            Data Availability
          </button>
          <button
            className={getClick === 3 ? "secondary_btn" : "primary_btn"}
            onClick={() => {
              handleClick(3);
              scrollToTop();
              setMobileMenuOpen(false);
            }}
          >
            Data Strategy
          </button>
          <button
            className={getClick === 4 ? "secondary_btn" : "primary_btn"}
            onClick={() => {
              handleClick(4);
              scrollToTop();
              setMobileMenuOpen(false);
            }}
          >
            Other AI Product
          </button>
          <button
            className={getClick === 5 ? "secondary_btn" : "primary_btn"}
            onClick={() => {
              handleClick(5);
              scrollToBottom();
              setMobileMenuOpen(false);
            }}
          >
            About US
          </button>
          <button
            className={getClick === 6 ? "secondary_btn" : "primary_btn"}
            onClick={() => {
              handleClick(6);
              scrollToBottom();
              setMobileMenuOpen(false);
            }}
          >
            Contact US
          </button>
          
        </div>
        <div className="light_mode">
          <span onClick={toggleTheme}>
            {
              theme === "light-theme" ? <span><i className="ri-moon-line"></i>Dark Mode</span> : <span><i className="ri-sun-line"></i>Light Mode</span>
            }
            
          </span>
        </div>
        <span className="mobile_menu" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
        <i className="ri-menu-line"></i>
        </span>
      </div>
      
    </header>
  );
};

export default Header;
