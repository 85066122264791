import React from "react";
import "../../styles/team.css";
import Slider from "react-slick";
import bhagi from "../../Assets/Bhagi.png";
import dharr from "../../Assets/Dharr.png";
import garima from "../../Assets/garima.png";
import olive from "../../Assets/olive.png";
import pawan from "../../Assets/Pawan.png";
import rohit from "../../Assets/rohit.png";
import sonal from "../../Assets/Sonal.png";
import sushil from "../../Assets/Sushil.png";

const teamMembers = [
  {
    imgUrl: sushil,
    name: "Sushil Kumar",
    linkedin: "https://www.linkedin.com/in/sushil-kumar-b510034b/",
  },
  {
    imgUrl: bhagi,
    name: "Bhagirath",
    linkedin: "https://www.linkedin.com/in/bhagirath-bhardwaj-90b94626/",
  },
  {
    imgUrl: garima,
    name: "Garima",
  },
  {
    imgUrl: olive,
    name: "Olive",
  },
  {
    imgUrl: rohit,
    name: "Rohit",
  },
  {
    imgUrl: pawan,
    name: "Pawan",
  },
  {
    imgUrl: dharr,
    name: "Dharr",
  },
  {
    imgUrl: sonal,
    name: "Sonal",
  },
];

const Team = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 4000,
    slidesToShow: 5,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 430, 
        settings: {
          slidesToShow: 3, 
          slidesToScroll: 3, 
        },
      },
    ],

    
  };
  return (
    <section>
      <div className="team_top-content">
        <h2>
          Meet Our Core <span className="highlight">NextBit</span> Team
        </h2>
      </div>
      <div className="team_cards_container">
        <Slider {...settings}>
          {teamMembers.map((member, index) => (
            <div className="team_card" key={index}>
              <div className="imgBx">
                <img src={member.imgUrl} alt={member.name} />
              </div>
              <div className="caption">
                <h3>{member.name}</h3>
                {/* <p>{member.role}</p> */}
                {index < 2 && (
                  <div className="social-links">
                    <a
                      href={member.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i class="ri-linkedin-box-fill"></i>
                    </a>
                    
                    {/* <a href="/"><i class="ri-twitter-fill"></i></a> */}
                  </div>
                )}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Team;
