import './App.css';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import Clients from './Components/UI/Clients';
import GetInTouch from './Components/UI/GetInTouch';
import Hero from './Components/UI/Hero';
import Service from './Components/UI/Service';
import Team from './Components/UI/Team';
import React, { useEffect, useState } from "react";
function App() {
  const [getClick, setclick] = useState(0);
  const [theme, setTheme] = useState('light-theme');

  const toggleTheme = () => {
    theme === "light-theme" ? setTheme("") : setTheme("light-theme")
  }

  useEffect(()=>{
    document.body.className = theme
  },[theme])

  const handleClick = (e) => {
    setclick(e)
  };
  
  return <>
    <Header getClick={getClick} theme={theme} toggleTheme={toggleTheme} handleClick={handleClick} />
    <Hero getClick={getClick} theme={theme} toggleTheme={toggleTheme} handleClick={handleClick}/>
    {/* <Counter/> */}
    <Service getClick={getClick} handleClick={handleClick} />
    <Clients/>
    {/* <Testimonial/> */}
    <Team/>
    <GetInTouch/>
    <Footer/>
    </>;
}

export default App;
