import React from 'react';
import "../Footer/footer.css";




const Footer = () => {
  return <footer className="footer">
    <div className="container">
        <p className="copyright">Copyright 2023-2024, Developed By NextBit. All right reserved. </p>
    </div>
  </footer>
}

export default Footer
