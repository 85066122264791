import React from 'react';
import "../../styles/client.css";
import olx_logo from "../../Assets/OLX-Logo.png";
import astra_logo from "../../Assets/astra-logo.png";
import cartopnews from "../../Assets/cartopnews.jpg"
import olx_astra from  "../../Assets/olx-astra.jpg";
import lucky2 from "../../Assets/lucky2.png";
// import opensqool from "../../Assets/OpenSooq_Logo.png"


const Clients = () => {
  return <section id="clients">
    <div className="container">
    <div className="client_top-content">
    <h2>Who all are using our Product</h2>
    </div>
    <div className='company_logo'>
    <img src={olx_logo} alt="olx" />
    <img src={astra_logo} alt="astra" />
    <img src={cartopnews} alt="cartopnews" id='cartop'/>
    <img src={olx_astra} alt="olx_astra" />
    <img src={lucky2} alt="lucky" id='lucky' />
    {/* <img src={opensqool} alt="opensqool" /> */}
    </div>
    </div>
  </section>
}

export default Clients
