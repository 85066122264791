import React from "react";
import "../../../styles/features.css";
import businessdata from "../../../Assets/tailer_to_you_need.jpeg";
import handsOn from "../../../Assets/consulting_handon_executation.jpeg";
import diverse from "../../../Assets/diverse_exper.jpeg";

const FeaturesDataStrategy = () => {
  return (
    <section id="service">
      <div className="container">
        <div className="features_top-content">
          <h2>HIGHLIGHTED FEATURES</h2>
          <h2 className="highlight">Make Your Work Easier & Faster !</h2>
        </div>
        <div className="features_container">
          {/* Feature 1 */}
          <div className="feature">
            <div className="feature_img">
              <img src={businessdata} alt="businessdata" />
            </div>
            <div className="feature_content">
              <h2>Tailored Data and Business Strategies:</h2>
              <p className="description">
                Collaborate with our experts to develop strategies aligned with
                your organizational objectives, incorporating industry-specific
                insights.
              </p>
            </div>
          </div>

          {/* Feature 2 */}
          <div className="feature">
            <div className="feature_content">
              <h2>Hands-On Execution Support:</h2>
              <p className="description">
                We go beyond strategy delivery, actively assisting in execution
                to translate plans into measurable outcomes.
              </p>
            </div>
            <div className="feature_img">
              <img src={handsOn} alt="handson" />
            </div>
          </div>

          {/* Add more features as needed */}
          {/* Feature 3 */}
          <div className="feature">
            <div className="feature_img">
              <img src={diverse} alt="diverse" />
            </div>
            <div className="feature_content">
              <h2>Diverse expertise areas:</h2>
              <p className="description">
                Our capabilities include expertise in artificial intelligence,
                business processes, customer experience, product and sales
                operations.
              </p>
            </div>
          </div>

       

        </div>
      </div>
    </section>
  );
};

export default FeaturesDataStrategy;
