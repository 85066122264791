import React from "react";
import "../../../styles/features.css";
import intellialgo from "../../../Assets/Final_Select_Intelegent_Algo.jpeg";
import businessacumen from "../../../Assets/Final_Select_BusinessAcq.jpeg";
import analysis from "../../../Assets/Final_Select_Visulation.jpeg";
import privacy from "../../../Assets/Fianl_Select_colwithprivacy.jpeg";

const Features = () => {
  return (
    <section id="service">
      <div className="container">
        <div className="features_top-content">
          <h2>HIGHLIGHTED FEATURES</h2>
          <h2 className="highlight">Make Your Work Easier & Faster !</h2>
        </div>
        <div className="features_container">
          {/* Feature 1 */}
          <div className="feature">
            <div className="feature_img">
              <img src={intellialgo} alt="intellialgo" />
            </div>
            <div className="feature_content">
              <h2>Intelligent algorithm answering complex questions :</h2>
              <p className="description">
                Aana is built to answer questions beyond single numbers and
                address complex questions related to business metric trend,
                growth, share which are critical to daily decisions
              </p>
            </div>
          </div>

          {/* Feature 2 */}
          <div className="feature">
            <div className="feature_content">
              <h2>Business-specific Acumen :</h2>
              <p className="description">
                Aana build tailor-made analysis, guide users in asking
                questions,and enrich insights with suitable charts & tables and
                additional explanations
              </p>
            </div>
            <div className="feature_img">
              <img src={businessacumen} alt="business" />
            </div>
          </div>

          {/* Add more features as needed */}
          {/* Feature 3 */}
          <div className="feature">
            <div className="feature_img">
              <img src={analysis} alt="analysis" />
            </div>
            <div className="feature_content">
              <h2>
              Analysis and visualization combining internal & external data :
              </h2>
              <p className="description">
                Generate descriptive analysis to prescriptive analysis
                integrating internal and external competitive & macro-economic
                trends & easily auto-build dashboards to visulaize
              </p>
            </div>
          </div>

          {/* Feature 4 */}
          <div className="feature">
            <div className="feature_content">
              <h2>Collaboration with privacy at core :</h2>
              <p className="description">
                User can share, reproduce, and collaborate on analyses with
                confidence, powered by privacy secure technology and
                performance-driven querying
              </p>
            </div>
            <div className="feature_img">
              <img src={privacy} alt="privacy" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
