import React, { useState, useEffect } from "react";
import "../../styles/hero.css";
import intelligo from "../../Assets/png-1.png"
import businessacumen from "../../Assets/png-2.png";
import analysis from "../../Assets/png-3.png";
import privacy from "../../Assets/png-4.png";

const Hero = () => {
  const images = [intelligo, businessacumen, analysis, privacy];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); 

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <section className="hero_section">
      <div className="container">
        <div className="hero_wrapper">
          <div className="hero_content">
            <div>
              <h2>NEXT GENRATION BUSINESS</h2>
              <h2>INTELLIGENCE PLATFORM</h2>
            </div>
            <p className="description">
              Empower organization to thrive in the digital age by integrating AI to
              data access, accelerating decision-making, and optimizing costs.
            </p>
          </div>
          <div className="hero_img">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`heroImage-${index}`}
                className={index === currentImageIndex ? "visible" : "hidden"}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
